import React from 'react'
import './current-weather.scss'

const CurrentWeather = ({ data }) => {
  //Function to convert pressure from hPa to inHg
  const convertPressureToInHg = (pressure) => {
    return (pressure * 0.02953).toFixed(2)//Conversion factor for hPa to inHg
  }

  return (
    <div className="weather">
      <div className="top">
        <div>
          <p className="city">{data.city}</p>
          <p className="weather-description">{data.weather[0].description}</p>
        </div>
        <img
          alt="weather"
          className="weather-icon"
          src={`/icons/${data.weather[0].icon}.png`}
        />
      </div>
      <div className="bottom">
        <p className="temperature">
          {Math.round((data.main.temp * 9) / 5 + 32)}°F
        </p>{' '}
        <div className="details">
          <div className="parameter-row">
            <span className="parameter-label">Details:</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label"> - Feels like</span>
            <span className="parameter-value">
              {Math.round((data.main.feels_like * 9) / 5 + 32)}°F
            </span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label"> - Wind</span>
            <span className="parameter-value">
              {Math.round(data.wind.speed * 2.237)} mph
            </span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label"> - Humidity</span>
            <span className="parameter-value">{data.main.humidity}%</span>
          </div>
          <div className="parameter-row">
            <span className="parameter-label"> - Pressure</span>
            <span className="parameter-value">
              {convertPressureToInHg(data.main.pressure)} inHg
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CurrentWeather
