import './index.scss';

const PageNotFound = () => {
    return (
        <div className="page-not-found-container">
            <div className="page-not-found-content">
                <h1 className="page-not-found-title">Page Not Found</h1>
                <p className="page-not-found-message">I'm sorry, the page you requested could not be found. Please Refer to the sidebar for redirection.</p>
            </div>
        </div>
    );
}

export default PageNotFound;
