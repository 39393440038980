import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const refForm = useRef()

  useState(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_y3xuw0b',
        'template_8nrbwsq',
        refForm.current,
        'M3nvfdnNOxH6rXKP1'
      )
      .then(
        () => {
          alert('Thank you for reaching out to me, I will get back with you soon!');
          refForm.current.reset();
        },
        () => {
          alert('Failed to send the message, please try again');
        }
      );
  };

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm actively seeking software engineering internships and would be
            delighted to hear from you. I invite you to connect with me using
            the form provided below. Alternatively, you can reach out via email,
            conveniently listed to the right, or through LinkedIn, accessible at
            the bottom left of this page in the sidebar.
          </p>
          <div className="contact-form">
            <form ref={refForm} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input type="text" name="from_name" placeholder="Name" required />
                </li>
                <li className="half">
                  <input
                    type="email"
                    name="from_email"
                    placeholder="Email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Cody Bentlage,
          <br />
          USA
          <br />
          30.6280, -96.3344 <br />
          College Station, Texas <br />
          <span>CodyBentlage@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[30.628, -96.3344]} zoom={7}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[30.628, -96.3344]}>
              <Popup>
                Cody lives here, but he's willing to move for an internship :)
              </Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact;
