import './index.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import * as Yup from 'yup'

const Registration = () => {
  const navigate = useNavigate()

  const initialValues = {
    username: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().min(3, "Username must be at least 3 characters").max(15, "Username can't be larger than 15 characters").required("You must input a username"),
    password: Yup.string().min(7, "Password must be at least 7 characters").max(20, "Password can't be more than 20 characters").required("You must input a password"),
  })

  const onSubmit = (data) => {
    axios
      .post('/api/auth', data)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          navigate(`/login`)
          alert('Account created, please log in')
        }
      })
      .catch((error) => {
        console.error(error)
        alert('An error occurred while creating the account.')
      })
  }

  return (
    <div className="login-container">
      <div className="form-wrapper">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <label htmlFor="username">Username:</label>
            <ErrorMessage name="username" component="post-span" />
            <Field
              id="username"
              name="username"
              placeholder="(Ex. John123...)"
              className="input-field" // Add className to match the style
            />

            <label htmlFor="password">Password:</label>
            <ErrorMessage name="password" component="post-span" />
            <Field
              type="password"
              id="password"
              name="password"
              placeholder="(Your Password...)"
              className="input-field" // Add className to match the style
            />

            <button className="login-button" type="submit">
              Create Account
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default Registration
