import React, { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { GeoApiOptions, GEO_API_URL } from '../../Api.js'
import './index.scss' // Import your SCSS file for custom styles

const Search = ({ onSearchChange }) => {
  const [search, setSearch] = useState(null)

  const loadOptions = (inputValue) => {
    return fetch(
      `${GEO_API_URL}/cities?&namePrefix=${inputValue}`,
      GeoApiOptions
    )
      .then((response) => response.json())
      .then((response) => {
        return {
          options: response.data.map((city) => {
            return {
              value: `${city.latitude} ${city.longitude}`,
              label: `${city.name}, ${city.countryCode}`,
            }
          }),
        }
      })
  }

  const handleOnChange = (searchData) => {
    setSearch(searchData)
    onSearchChange(searchData)
  }

  return (
    <div className="custom-search-bar-container">
      {' '}
      {/* Apply styles to this container */}
      <AsyncPaginate
        className="custom-search-bar"
        placeholder="Search for city"
        debounceTimeout={600}
        value={search}
        onChange={handleOnChange}
        loadOptions={loadOptions}
      />
    </div>
  )
}

export default Search

