import { useContext } from 'react'
import './index.scss'
import axios from 'axios'
import { AuthContext } from '../../AuthContext'
import { useNavigate } from 'react-router-dom'

const Settings = () => {
  const { authState, setAuthState } = useContext(AuthContext)
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('accessToken')
    setAuthState({ username: '', id: 0, status: false })
  }

  const handleLogoutClick = () => {
    logout()
    navigate('/')
    alert('You have logged out.')
  }

  const goToChangePassword = () => {
    navigate('/change-password')
  }
  const goToChangeUsername = () => {
    navigate('/change-username')
  }

  const deleteAccount = async () => {
    try {
      // Confirm deletion with the user
      const confirmation = window.confirm(
        'Are you sure you want to delete your account?'
      )
      if (!confirmation) return

      // Send delete request to backend
      const response = await axios.delete(
        `/api/auth/${authState.id}/${authState.username}`,
        {
          headers: {
            accessToken: localStorage.getItem('accessToken'),
          },
        }
      )

      // Check if deletion was successful
      if (response.status === 200) {
        alert(response.data)
        // Clear local storage and update authentication state
        logout()
        navigate('/')
      }
    } catch (error) {
      console.error('Error deleting account:', error)
      alert('An error occurred while deleting your account.')
    }
  }

  return (
    <div className="page-container">
      <div className="sidebar"></div>
      <div className="settings-page">
        <h1>Settings</h1>
        <div className="settings-content">
          <div className="setting-option">
            <label>Option 1:</label>
            <input type="text" placeholder="Enter Option 1 Value" />
          </div>
          <div className="setting-option">
            <label>Option 2:</label>
            <select>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
          <div className="setting-option">
            {authState.status && (
              <button onClick={goToChangePassword}>Change Password</button>
            )}
            {authState.status && (
              <button onClick={goToChangeUsername}>Change Username</button>
            )}
            {authState.status && (
              <button className="delete-account" onClick={deleteAccount}>
                Delete Account
              </button>
            )}
            {authState.status && (
              <span className="logout-link-button" onClick={handleLogoutClick}>
                Logout
              </span>
            )}
          </div>
          {/* Add more setting options as needed */}
        </div>
      </div>
    </div>
  )
}

export default Settings
