import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGitAlt,
  faGitlab,
  faJs,
  faNodeJs,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import {
  faC
} from '@fortawesome/free-solid-svg-icons'
const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useState(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            As an aspiring software engineer currently enrolled in computer science
            studies at Blinn College, I bring a strong foundation in C++
            programming and a drive to excel in the field of technology. My
            dedication to learning various languages is evident in the projects
            I undertake in my personal time. For instance, I have delved into
            JavaScript and SCSS to create this website utilizing React as the
            framework. Through this endeavor, I gained invaluable experience in
            SVG animation with GSAP, interactive hover effects using
            Animate.css, and seamless integration of page-loading animations
            with React-Loaders. Additionally, I explored advanced techniques
            such as implementing custom 3D CSS animations, integrating font
            icons, and designing a functional contact form using EmailJS within
            the React environment.
          </p>
          <p>
            Beyond web development, I have also immersed myself in SDL2, a
            versatile cross-platform development library. This exploration has
            allowed me to gain hands-on experience in leveraging hardware
            functionalities including audio, keyboard, mouse, joystick, and
            graphics. By actively seeking opportunities to expand my skill set
            and tackle challenging projects, I am committed to not only honing
            my expertise but also contributing meaningfully to innovative
            technological solutions.
          </p>
          <p>
            In addition to my proficiency in programming languages and React, I
            have also gained hands-on experience in deploying web applications
            through the implementation of a robust CI/CD pipeline. Leveraging my
            knowledge of YAML, I designed and configured a deployment
            process using GitLab for version control and AWS services such as
            ElasticBeanstalk, S3, IAM, and EC2. This involved creating custom
            roles within IAM to establish secure access permissions, ensuring
            that GitLab could interact with AWS ElasticBeanstalk for
            deployment. By orchestrating this CI/CD pipeline, I streamlined the
            development workflow, enabling rapid iterations and seamless
            deployment of updates to the website you are currently viewing.
          </p>
        </div>
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faGitlab} color="#F06529" />
            </div>
            <div className="face2">
              <FontAwesomeIcon
                icon={faNodeJs}
                color="#3c873a"
              />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faJs} color="#f0db4f" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faC} color="#2596be" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
