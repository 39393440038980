import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import LogoS from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faClose,
  faCloudRain,
  faCog,
  faCogs,
  faEnvelope,
  faGamepad,
  faHome,
  faPeace,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import {
  faGithub,
  faGitlab,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import { useState } from 'react'

const Sidebar = () => {
  const [showNav, setShowNav] = useState(false)

  return (
    <div className="nav-bar">
      <Link className="logo" to="/">
        <img className="c-logo" src={LogoS} alt="logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="Cody" />
      </Link>
      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink exact="true" activeclassname="active" to="/">
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="games-link"
          to="/games"
        >
          <FontAwesomeIcon icon={faGamepad} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="weather-link"
          to="/weather"
        >
          <FontAwesomeIcon icon={faCloudRain} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="social-link"
          to="/social"
        >
          <FontAwesomeIcon icon={faPeace} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
        >
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
        >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="linkedin-link"
          to="https://www.linkedin.com/in/cody-bentlage-126274237/"
        >
          <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="github-link"
          to="https://github.com/CodyBentlage/SDL2Game"
        >
          <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="gitlab-link"
          to="https://gitlab.com/CodyBentlage"
        >
          <FontAwesomeIcon icon={faGitlab} color="#4d4d4e" />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="linkedin-link"
          to="/settings"
        >
          <FontAwesomeIcon icon={faCogs} color="#4d4d4e" />
        </NavLink>
        <FontAwesomeIcon
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="#ffd700"
          size="xs"
          className="close-icon"
        />
      </nav>
      <ul className={showNav ? 'mobile-show-links' : ''}>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/cody-bentlage-126274237/"
          >
            <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/CodyBentlage/SDL2Game"
          >
            <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://gitlab.com/CodyBentlage"
          >
            <FontAwesomeIcon icon={faGitlab} color="#4d4d4e" />
          </a>
        </li>
        <li>
          <NavLink
            exact="true"
            activeclassname="active"
            className="settings-link"
            to="/settings"
          >
            <FontAwesomeIcon icon={faCog} color="#4d4d4e" />
          </NavLink>
        </li>
      </ul>
      <FontAwesomeIcon
        onClick={() => setShowNav(true)}
        icon={faBars}
        color="#ffd700"
        size="xs"
        className="hamburger-icon"
      />
    </div>
  )
}

export default Sidebar
