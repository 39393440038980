import './index.scss'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ProfilePage = () => {
  let { id } = useParams()
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [listOfPosts, setListOfPosts] = useState([])
  const [sortOption, setSortOption] = useState('option2') // Default to 'Sort by newest'

  useEffect(() => {
    axios.get(`/api/auth/basicinfo/${id}`).then((response) => {
      setUsername(response.data.username)
    })
    axios.get(`/api/posts/byUserId/${id}`).then((response) => {
      setListOfPosts(response.data)
    })
  }, [id])

  const handlePostClick = (postId) => {
    navigate(`/post/${postId}`)
  }

  const handleSortChange = (event) => {
    setSortOption(event.target.value)
  }

  // Function to sort posts based on the selected sorting option
  const sortPosts = () => {
    switch (sortOption) {
      case 'option2': // Sort by oldest
        return [...listOfPosts].reverse()
      case 'option3': // Sort by most liked
        return [...listOfPosts].sort((a, b) => b.Likes.length - a.Likes.length)
      default: // Sort by newest (default)
        return [...listOfPosts]
    }
  }

  return (
    <div className="profile-page-container">
      <div className="profile-page-container-words">Profile page</div>
      <div className="profile-page-basic-info"> Username: {username} </div>
      <div></div>
      <select
        className="sort-by-profile"
        value={sortOption}
        onChange={handleSortChange}
      >
        <option value="option2">Sort by newest</option>
        <option value="option1">Sort by oldest</option>
        <option value="option3">Sort by most popular</option>
      </select>
      <NavLink exact="true" activeclassname="active" to="/createpost">
        <FontAwesomeIcon
          icon={faPlus}
          className="create-post-link"
          color="#4d4d4e"
        />
      </NavLink>
      <label className="profile-post-label">Create a post</label>
      <div className="profile-page-list-of-user-posts">
        {sortPosts().map((value, key) => (
          <div
            key={key}
            className="profile-page-post"
            onClick={() => handlePostClick(value.id)}
          >
            <div className="profile-page-post-content">
              <div className="profile-page-post-title">{value.title}</div>
              <div className="profile-page-post-text">{value.postText}</div>
            </div>
            <div className="profile-page-post-details">
              <label>- {username}</label>
              <br />
              <label>Likes: {value.Likes.length}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfilePage
