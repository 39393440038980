import './index.scss' // Import your SCSS file
import Loader from 'react-loaders'

const Games = () => {
  const HandleClickBoomin = () => {
    // Navigate to the itcho.io URL
    window.location.href = 'https://cody-bentlage.itch.io/boomin'
  }

  return (
    <div className="games-container">
      <div className="game">
        <div className="game-description">
          I've created this game using C and SDL2 for Windows and Linux computers, so far
          it's the only game I've created. There was no game engine involved in
          its creation, only C and SDL2. Feel free to check it out!
        </div>
        <button className="game-button" onClick={HandleClickBoomin}>
          <span className="play-game">Play My Game</span>
        </button>
      </div>
      <Loader className="loader" type="pacman" />
    </div>
  )
}

export default Games