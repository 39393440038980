export const GeoApiOptions = {
  method: 'GET',
  headers: {
    'X-RapidAPI-Key': '533623cbf0mshee3a1ff13cd703fp18eed7jsn9df2faad5160',
    'X-Rapid-API-Host': 'wft-geo-db.p.rapidapi.com',
  },
}
export const GEO_API_URL = 'https://wft-geo-db.p.rapidapi.com/v1/geo'
export const WEATHER_API_URL = 'https://api.openweathermap.org/data/2.5'
export const WEATHER_API_KEY = 'db693ba4c09f84b3bfa6971cf0e1157f'
