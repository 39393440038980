import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import Sidebar from '../Sidebar'
import './index.scss'
import axios from 'axios'
import { AuthContext } from '../../AuthContext'
import { useEffect, useState } from 'react'

const Layout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust the threshold as needed
    }

    // Listen for window resize events
    window.addEventListener('resize', handleResize)

    // Initial check on component mount
    handleResize()

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const logout = () => {
    localStorage.removeItem('accessToken')
    setAuthState({ username: '', id: 0, status: false })
  }

  const handleLogoutClick = () => {
    logout()
    navigate('/')
    alert('You have logged out.')
  }

  const [authState, setAuthState] = useState({
    username: '',
    id: 0,
    status: false,
  })

  useEffect(() => {
    axios
      .get('/api/auth', {
        headers: {
          accessToken: localStorage.getItem('accessToken'),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState((prevAuthState) => ({ ...prevAuthState, status: false }))
        } else {
          setAuthState({
            username: response.data.username,
            id: response.data.id,
            status: true,
          })
        }
      })
  }, [])

  return (
    <div className="App">
      <AuthContext.Provider value={{ authState, setAuthState }}>
        {!authState.status && (
          <Link to="/login" className="login-link-button">
            Login
          </Link>
        )}
        <Sidebar />
        {authState.status &&
          location.pathname !== '/settings' &&
          location.pathname !== `/profile/${authState.id}` &&
          !location.pathname.startsWith`/post` && ( // Render username if user is logged in and not on the settings page
            <h1
              className="username"
              onClick={() => navigate(`/profile/${authState.id}`)}
            >
              {authState.username}
            </h1>
          )}
        <div className="page">
          {location.pathname !== '/social' &&
            location.pathname !== '/weather' &&
            location.pathname !== '/games' &&
            location.pathname !== `/profile/${authState.id}` &&
            location.pathname !== '/createpost' &&
            !location.pathname.startsWith`/post` && ( // Conditionally render top tags except for the social, weather, and games pages
              <span className="tags top-tags">&lt;body&gt;</span>
            )}
          <Outlet />
          {location.pathname !== '/social' &&
            location.pathname !== '/weather' &&
            location.pathname !== '/games' &&
            location.pathname !== `/profile/${authState.id}` &&
            location.pathname !== '/createpost' &&
            !location.pathname.startsWith`/post` && ( // Conditionally render bottom tags except for the social, weather, and games pages
              <span className="tags bottom-tags">
                &lt;/body&gt;
                <br />
                <span className="bottom-tag-html">&lt;/html&gt;</span>
              </span>
            )}
        </div>
      </AuthContext.Provider>
      {authState.status &&
        location.pathname === `/profile/${authState.id}` &&
        !isMobile && ( // Check if not on mobile
          <span className="logout-link-button" onClick={handleLogoutClick}>
            Logout
          </span>
        )}
    </div>
  )
}

export default Layout
