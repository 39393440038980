import React, { useState, useContext } from 'react'
import axios from 'axios'
import './index.scss'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../AuthContext'

const Login = () => {
  const navigate = useNavigate()
  const { setAuthState } = useContext(AuthContext)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleKeyPress = (event) => {
    if (
      event.key === 'Enter' &&
      (event.target.type === 'password' || event.target.type === 'text')
    ) {
      login()
    }
  }

  const login = () => {
    const data = { username: username, password: password }
    axios.post('/api/auth/login', data).then((response) => {
      if (response.data.error) {
        alert(response.data.error)
      } else {
        localStorage.setItem('accessToken', response.data.token)
        setAuthState({
          username: response.data.username,
          id: response.data.id,
          status: true,
        })
        navigate(`/social`)
      }
    })
  }

  return (
    <div className="login-container">
      <input
        type="text"
        className="input-field"
        onChange={(event) => {
          setUsername(event.target.value)
        }}
        onKeyDown={handleKeyPress}
        placeholder="Username"
      />
      <input
        type="password"
        className="input-field"
        onChange={(event) => {
          setPassword(event.target.value)
        }}
        onKeyDown={handleKeyPress}
        placeholder="Password"
      />
      <button className="login-button" onClick={login}>
        Login
      </button>
      <Link to={'/registration'} className="registration-link">
        Don't have an account? Register here
      </Link>
    </div>
  )
}

export default Login
