import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './index.scss'

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('accessToken')
    navigate('/login') // Redirect to login page after logout
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      changePassword()
    }
  }

  const changePassword = () => {
    if (!newPassword.trim()) {
      alert('Please enter a non-empty password.')
      return
    }

    if (newPassword.length < 7 || newPassword.length > 21) {
      alert('Password must be between 7 and 21 characters.')
      return
    }

    axios
      .put(
        '/api/auth/change-password',
        { oldPassword: oldPassword, newPassword: newPassword },
        {
          headers: {
            accessToken: localStorage.getItem('accessToken'),
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          alert(response.data)
          logout()
        }
      })
      .catch((error) => {
        console.error(error)
        alert('An error occurred while changing password.')
      })
  }

  return (
    <div className="change-password-page">
      <h1 className="change-password">Change your password</h1>
      <input
        className="change-password-old"
        type="password"
        placeholder="Old Password..."
        onChange={(event) => {
          setOldPassword(event.target.value)
        }}
        onKeyPress={handleKeyPress}
      />
      <input
        className="change-password-new"
        type="password"
        placeholder="New Password..."
        onChange={(event) => {
          setNewPassword(event.target.value)
        }}
        onKeyPress={handleKeyPress}
      />
      <button className="change-pass" onClick={changePassword}>
        Save Changes
      </button>
    </div>
  )
}

export default ChangePassword
