import { useEffect, useState } from 'react'
import './index.scss'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

const Social = () => {
  const [likedPosts, setLikedPosts] = useState([])
  const [listOfPosts, setListOfPosts] = useState([])
  const [sortOption, setSortOption] = useState('option1') // Default to 'Sort by newest'
  const navigate = useNavigate()

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      navigate('/login')
    } else {
      axios
        .get('/api/posts', {
          headers: { accessToken: localStorage.getItem('accessToken') },
        })
        .then((response) => {
          // Reverse the order of posts to display newest posts on top
          setListOfPosts(response.data.listOfPosts.reverse())
          setLikedPosts(response.data.likedPosts.map((like) => like.PostId))
        })
    }
  }, [navigate])

  const handlePostClick = (postId) => {
    navigate(`/post/${postId}`)
  }

  const handleSortChange = (event) => {
    setSortOption(event.target.value)
  }

  const LikePost = (postId) => {
    axios
      .post(
        '/api/likes',
        { PostId: postId },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
      )
      .then((response) => {
        setListOfPosts((prevList) =>
          prevList.map((post) => {
            if (post.id === postId) {
              if (response.data.liked) {
                return { ...post, Likes: [...post.Likes, 0] }
              } else {
                const likesArray = [...post.Likes]
                likesArray.pop()
                return { ...post, Likes: likesArray }
              }
            }
            return post
          })
        )

        setLikedPosts((prevLikedPosts) =>
          response.data.liked
            ? [...prevLikedPosts, postId]
            : prevLikedPosts.filter((id) => id !== postId)
        )
      })
  }

  // Function to sort posts based on the selected sorting option
  const sortPosts = () => {
    switch (sortOption) {
      case 'option2': // Sort by oldest
        return [...listOfPosts].reverse()
      case 'option3': // Sort by most liked
        return [...listOfPosts].sort((a, b) => b.Likes.length - a.Likes.length)
      default: // Sort by newest (default)
        return [...listOfPosts]
    }
  }

  return (
    <div className="social-page">
      <div className="posts-container">
        <div className="Codys-page">Codys Social Media Page</div>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/createpost"
        >
          <FontAwesomeIcon icon={faPlus} className="add-post" color="#4d4d4e" />
        </NavLink>
        <label className="post-label">Create a post</label>
        <select
          className="sort-by"
          value={sortOption}
          onChange={handleSortChange}
        >
          <option value="option1">Sort by newest</option>
          <option value="option2">Sort by oldest</option>
          <option value="option3">Sort by most popular</option>
        </select>
        {sortPosts().map((value, key) => (
          <div
            key={key}
            className="post"
            onClick={() => handlePostClick(value.id)}
          >
            <div className="post-title">{value.title}</div>
            <div className="post-text">{value.postText}</div>
            <div className="post-username">
              <Link
                to={`/profile/${value.UserId}`}
                onClick={(e) => e.stopPropagation()}
              >
                -{value.username}
              </Link>
            </div>
            {likedPosts.includes(value.id) ? (
              <FavoriteIcon
                onClick={(e) => {
                  e.stopPropagation()
                  LikePost(value.id)
                }}
                className="like-post"
                style={{ fontSize: '24px', color: 'red' }}
              />
            ) : (
              <FavoriteBorderIcon
                onClick={(e) => {
                  e.stopPropagation()
                  LikePost(value.id)
                }}
                className="like-post"
                style={{ fontSize: '24px' }}
              />
            )}
            <label>{value.Likes.length}</label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Social
