import './App.scss'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Games from './components/Games'
import Weather from './components/Weather/weather'
import Social from './components/Social'
import CreatePost from './components/CreatePost'
import Post from './components/Post'
import Login from './components/Login'
import Registration from './components/Registration'
import Settings from './components/Settings'
import PageNotFound from './components/PageNotFound'
import ProfilePage from './components/ProfilePage'
import ChangePassword from './components/Password'
import ChangeUsername from './components/Username'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="games" element={<Games />} />
          <Route path="weather" element={<Weather />} />
          <Route path="social" element={<Social />} />
          <Route path="createpost" element={<CreatePost />} />
          <Route path="post/:id" element={<Post />} />
          <Route path="login" element={<Login />} />
          <Route path="registration" element={<Registration />} />
          <Route path="settings" element={<Settings />} />
          <Route path="profile/:id" element={<ProfilePage />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="change-username" element={<ChangeUsername />} />
          <Route path="*" element={<PageNotFound/>} />                  
        </Route>
      </Routes>
    </>
  )
}

export default App
