import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './index.scss'

const ChangeUsername = () => {
  const [oldPassword, setOldPassword] = useState('')
  const [newUsername, setNewUsername] = useState('')
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('accessToken')
    navigate('/login') // Redirect to login page after logout
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      changeUsername()
    }
  }

  const changeUsername = () => {
    if (!newUsername.trim()) {
      alert('Please enter a non-empty username.')
      return
    }

    if (newUsername.length < 3 || newUsername.length > 21) {
      alert('Username must be between 3 and 21 characters.')
      return
    }

    axios
      .put(
        '/api/auth/change-username',
        { oldPassword: oldPassword, newUsername: newUsername },
        {
          headers: {
            accessToken: localStorage.getItem('accessToken'),
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          alert(response.data)
          logout()
        }
      })
      .catch((error) => {
        console.error(error)
        alert('An error occurred while changing username.')
      })
  }

  return (
    <div className="change-username-page">
      <h1 className="change-username">Change your username</h1>
      <input
        className="password-field"
        type="password"
        placeholder="Password..."
        value={oldPassword}
        onChange={(event) => setOldPassword(event.target.value)}
        onKeyPress={handleKeyPress}
      />
      <input
        className="change-username"
        type="text"
        placeholder="New Username..."
        value={newUsername}
        onChange={(event) => setNewUsername(event.target.value)}
        onKeyPress={handleKeyPress}
      />
      <button className="change-pass" onClick={changeUsername}>
        Save Changes
      </button>
    </div>
  )
}

export default ChangeUsername
