import { useState } from 'react'
import { WEATHER_API_KEY, WEATHER_API_URL } from '../../Api.js'
import Search from '../Search/index.js'
import CurrentWeather from '../CurrentWeather/current-weather.js'
import Forecast from '../Forecast/forecast.js'
import './weather.scss'
import Loader from 'react-loaders'

const Weather = () => {
  const [currentWeather, setCurrentWeather] = useState(null)
  const [forecast, setForecast] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchWeatherData = (searchData) => {
    if (!searchData) return

    const [lat, lon] = searchData.value.split(' ')

    setIsLoading(true)

    const currentWeatherFetch = fetch(
      `${WEATHER_API_URL}/weather?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    )
    const forecastFetch = fetch(
      `${WEATHER_API_URL}/forecast?lat=${lat}&lon=${lon}&appid=${WEATHER_API_KEY}&units=metric`
    )

    Promise.all([currentWeatherFetch, forecastFetch])
      .then(async (responses) => {
        const weatherResponse = await responses[0].json()
        const forecastResponse = await responses[1].json()

        setCurrentWeather({ city: searchData.label, ...weatherResponse })
        setForecast({ city: searchData.label, ...forecastResponse })
      })
      .catch(console.log)
      .finally(() => setIsLoading(false))
  }

  return (
    <div className="container weather-box-container">
      <Search onSearchChange={fetchWeatherData} />
      {isLoading && <Loader type="pacman" />}
      {currentWeather && <CurrentWeather data={currentWeather} />}
      {forecast && <Forecast data={forecast} />}
    </div>
  )
}

export default Weather
