import './index.scss'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from 'axios'
import * as Yup from 'yup' // Import everything from yup, as a variable called yup
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const CreatePost = () => {
  const navigate = useNavigate()

  const handlePostClick = (postId) => {
    navigate(-1)
  }

  const initialValues = {
    title: '',
    postText: '',
  }

  const onSubmit = (data) => {
    axios
      .post('/api/posts', data, {
        headers: { accessToken: localStorage.getItem('accessToken') },
      })
      .then((response) => {
        handlePostClick()
      })
  }

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      navigate('/login')
    }
  }, [navigate])

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('You have to write a title').max(255, "Title can't be more than 255 characters"),
    postText: Yup.string().required('You have to write a post').max(255, "Post can't be more than 255 characters"),
  });

  return (
    <div className="create-post-container">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <label htmlFor="title">Title:</label>
          <ErrorMessage name="title" component="post-span" />
          <Field id="title" name="title" placeholder="(Ex. Title..." />
          <label htmlFor="postText">Post:</label>
          <ErrorMessage name="postText" component="post-span" />
          <Field
            as="textarea"
            id="postText"
            name="postText"
            placeholder="(Ex. Post..."
          />
          <button className="post-button" type="submit">
            Post
          </button>
        </Form>
      </Formik>
    </div>
  )
}

export default CreatePost
