import React, { useContext, useEffect, useState } from 'react'
import './index.scss'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../AuthContext'

const Post = () => {
  let { id } = useParams()
  const navigate = useNavigate()
  const [postObject, setPostObject] = useState({})
  const [comments, setComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const { authState } = useContext(AuthContext)

  useEffect(() => {
    axios.get(`/api/posts/byId/${id}`).then((response) => {
      setPostObject(response.data)
    })

    axios.get(`/api/comments/${id}`).then((response) => {
      setComments(response.data)
    })
  }, [id])

  const addComment = () => {
    // Check if newComment is empty
    if (!newComment.trim()) {
      alert('Please enter a non-empty comment.')
      return
    }

    if (newComment.length > 255) {
      alert(
        `Comment must be 255 characters or less. Your Current character count is ${newComment.length}`
      )
      return
    }

    axios
      .post(
        '/api/comments',
        {
          commentBody: newComment,
          PostId: id,
        },
        {
          headers: {
            accessToken: localStorage.getItem('accessToken'),
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error)
        } else {
          const commentToAdd = {
            commentBody: newComment,
            username: response.data.username,
          }
          setComments([...comments, commentToAdd])
          setNewComment('')
        }
      })
  }

  const deleteComment = (id) => {
    // Check if the comment ID is valid
    if (!id) {
      alert('Refreshing the page, please try again')
      window.location.reload()
      return
    }

    // Display a confirmation dialog
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this comment?'
    )

    // Check if the user confirmed the deletion
    if (isConfirmed) {
      axios
        .delete(`/api/comments/${id}`, {
          headers: { accessToken: localStorage.getItem('accessToken') },
        })
        .then(() => {
          setComments(
            comments.filter((val) => {
              return val.id !== id
            })
          )
        })
        .catch((error) => {
          console.error('Error deleting comment:', error)
        })
    }
  }

  const deletePost = (id) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this post?'
    )
    // Check if the user confirmed the deletion
    if (isConfirmed) {
      axios
        .delete(`/api/posts/${id}`, {
          headers: { accessToken: localStorage.getItem('accessToken') },
        })
        .then(() => {
          navigate('/social')
          alert('Post Deleted')
        })
        .catch((error) => {
          console.error('Error deleting post:', error)
          alert('Failed to delete post. Please try again later.')
        })
    }
  }

  const editPost = (option) => {
    let newInput
    if (option === 'title') {
      newInput = prompt('Enter New Title')
    } else {
      newInput = prompt('Enter New Text')
    }

    // Check if user clicked cancel or closed the prompt dialog
    if (newInput === null) {
      return
    }

    // Check if new input is empty
    if (!newInput.trim()) {
      alert('Please enter a non-empty input.')
      return
    }

    // Check if input exceeds 255 characters
    if (newInput.length > 255) {
      alert(
        `Input must be 255 characters or less. Your current character count is ${newInput.length}`
      )
      return
    }

    axios
      .put(
        option === 'title'
          ? '/api/posts/title'
          : '/api/posts/postText',
        { [option === 'title' ? 'newTitle' : 'newText']: newInput, id: id },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
      )
      .then(() => {
        setPostObject({
          ...postObject,
          [option === 'title' ? 'title' : 'postText']: newInput,
        })
      })
      .catch((error) => {
        console.error(
          `Error editing ${option === 'title' ? 'title' : 'text'}:`,
          error
        )
      })
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addComment()
    }
  }

  const goBack = () => {
    navigate(-1)
  }

  const updateComment = (commentId, newCommentBody) => {
    axios
      .put(
        `/api/comments/${commentId}`,
        { commentBody: newCommentBody },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
      )
      .then((response) => {
        if (!response.data.error) {
          // Update the comments state with the updated comment
          setComments((prevComments) =>
            prevComments.map((comment) =>
              comment.id === commentId
                ? { ...comment, commentBody: newCommentBody }
                : comment
            )
          )
        } else {
          alert('Failed to update comment')
        }
      })
      .catch((error) => {
        console.error('Error updating comment:', error)
        alert('Error updating comment. Please try again later.')
      })
  }

  const handleSelectChange = (selectedOption, commentId) => {
    switch (selectedOption) {
      case 'option1':
        if (authState.username === postObject.username) {
          editPost('title')
        }
        break
      case 'option2':
        if (authState.username === postObject.username) {
          editPost('text')
        }
        break
      case 'option3':
        deletePost(postObject.id)
        break
      case 'option4':
        const newCommentBody = prompt('Enter New Comment')
        if (newCommentBody !== null) {
          updateComment(commentId, newCommentBody)
        }
        break
      case 'option5':
        deleteComment(commentId)
        break
      default:
        break
    }
  }

  return (
    <div className="post-page">
      <button
        className="back-button"
        onClick={() => {
          goBack()
        }}
      >
        Go Back
      </button>
      <div className="left-side-post">
        <div className="post-title">{postObject.title} </div>
        <div className="post-text">{postObject.postText} </div>
        <div className="post-username">
          <Link to={`/profile/${postObject.UserId}`}>
            -{postObject.username}
          </Link>
          {authState.username === postObject.username && (
            <select
              onChange={(e) => handleSelectChange(e.target.value)}
              className="post-options"
            >
              <option value="" disabled selected hidden>
                Options
              </option>
              <option value="option1">Edit post title</option>
              <option value="option2">Edit post text</option>
              <option value="option3">Delete post</option>
            </select>
          )}
        </div>
      </div>
      <div className="right-side-post">
        <div className="comments-section">
          <h2>Comments</h2>
          <div className="list-of-comments">
            {comments.map((comment) => (
              <div key={comment.id} className="comment">
                <label className="comment-username">
                  <Link className='comment-username' to={`/profile/${postObject.UserId}`}>
                    {comment.username}
                  </Link>
                </label>
                {comment.commentBody}
                {authState.username === comment.username && (
                  <select
                    onChange={(e) =>
                      handleSelectChange(e.target.value, comment.id)
                    }
                    className="comment-options"
                  >
                    <option value="" disabled selected hidden>
                      Options
                    </option>
                    <option value="option4">Edit comment</option>
                    <option value="option5">Delete comment</option>
                  </select>
                )}
              </div>
            ))}
          </div>
          <div className="add-comment">
            <input
              value={newComment}
              type="text"
              placeholder="Comment"
              onChange={(event) => {
                setNewComment(event.target.value)
              }}
              onKeyPress={handleKeyPress}
            />
            <button onClick={addComment} className="post-comment">
              Add Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post
